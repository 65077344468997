import { ArrowBackOutlined } from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import server from "../../server";
import AllOrders from "../Dashboard/Layout/AllOrders";
import TotalOrder from "../Inventory/Layout/TotalOrder";
import CustomerAddress from "./Layout/CustomerAddress";
import CustomerDetail from "./Layout/CustomerDetail";
import CustomerViewTable from "./Layout/CustomerViewTable";

const CustomerOrder = () => {
  const location = useLocation();
  const data = location.state?.data;
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [oriorders, setOriOrders] = useState([]);

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  };
  useEffect(() => {
    let order_data = [];
    axios.get(`${server}/orders/getorders`, requestOptions).then((res) => {
      console.log(res.data.results);
      // console.log(data.id);
      res.data.results.map((item) => {
        if (item.order.user == data.id) order_data.push(item);
      });
      console.log(order_data);
      setOrders(order_data);
      setOriOrders(order_data);
    });
  }, []);
  return (
    <div>
      <ArrowBackOutlined onClick={() => navigate("/customers")} />
      <div className="customer-order-main-div">
        <div className="customer-detail-card">
          <CustomerDetail data={data} />
        </div>
        <div className="customer-address-card">
          <CustomerAddress data={data} />
        </div>
        <div className="cutomer-order-card">
          <TotalOrder orders={orders} />
        </div>
      </div>
      <div className="customer-order-main-div">
        <div className="customer-detail-card">
          <AllOrders orders={orders} />
        </div>
        {/* <div className="customer-address-card">
          <Canceled />
        </div>
        <div className="cutomer-order-card">
          <Abandoned />
        </div> */}
      </div>
      <CustomerViewTable
        orders={orders}
        oriorders={oriorders}
        setOrders={setOrders}
      />
    </div>
  );
};

export default CustomerOrder;
