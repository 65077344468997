import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import JSZip from "jszip";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  alpha,
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import server from "../../../server";

import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import DashboardContext from "../../Context/DashboardContext";
// import Select from '@mui/material/Select';

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "Customer_details",
    numeric: false,
    disablePadding: true,
    label: "Customer details",
  },
  {
    id: "PickUp Address",
    numeric: true,
    disablePadding: false,
    label: "Pickup Address",
  },
  {
    id: "Created Date/Time",
    numeric: true,
    disablePadding: false,
    label: "Created Date/Time",
  },
  {
    id: "Returning Reason",
    numeric: true,
    disablePadding: false,
    label: "Returning Reason",
  },
  // {
  //   id: "Action",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Order Total",
  // },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Order Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const ref = useRef();

  const handlesearch = async (e) => {
    if (e.target.value != "") {
      const filteredRows = await props.oriorderData.filter((row) => {
        return row?.orderId?.recipientName
          ?.toLowerCase()
          ?.includes(e.target.value.toLowerCase());
      });

      if (props.status !== "all") {
        const finalstatusrows = await filteredRows.filter((row) => {
          return row?.status === props.status;
        });
        props.setOrderData([...finalstatusrows].reverse());
      } else {
        props.setOrderData([...filteredRows].reverse());
      }
    } else {
      if (props.status !== "all") {
        const finalstatusrows = await props.oriorderData.filter((row) => {
          return row?.status === props.status;
        });
        props.setOrderData([...finalstatusrows].reverse());
      } else {
        props.setOrderData([...props.oriorderData].reverse());
      }

      // setRows(filteredRows);
      // console.log(filteredRows);
    }
  };

  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }

  const getdoc = async () => {
    let length = props.orderData.length;
    var zip = new JSZip();
    props.setLoading(true);

    for (let i = 0; i < length; i++) {
      let report = new jsPDF("portrait", "pt", "a4");
      await report
        .html(document.querySelector("#a" + i.toString()))
        .then((result) => {
          // report.save('report.pdf');
          var pdfDataUrl = report.output("datauristring");
          // console.log(pdfDataUrl)
          zip.file(
            "Return" + props.orderData[i].returnId + "label.pdf",
            dataURLtoBlob(pdfDataUrl),
          );

          // zip.file("order" + i + ".pdf", report.save("report.pdf"));
        });
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      // see FileSaver.js
      console.log("sendin zip");
      saveAs(content, "labels.zip");
    });
    props.setLoading(false);
  };

  const getdocorders = async () => {
    let length = props.orderData.length;
    var zip = new JSZip();
    props.setLoading(true);

    for (let i = 0; i < length; i++) {
      let report = new jsPDF("portrait", "pt", "a4");
      await report
        .html(document.querySelector("#b" + i.toString()))
        .then((result) => {
          // report.save('report.pdf');
          var pdfDataUrl = report.output("datauristring");
          // console.log(pdfDataUrl)
          zip.file(
            "Return" + props.orderData[i].returnId + ".pdf",
            dataURLtoBlob(pdfDataUrl),
          );

          // zip.file("order" + i + ".pdf", report.save("report.pdf"));
        });
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      // see FileSaver.js
      console.log("sendin zip");
      saveAs(content, "returns.zip");
    });
    props.setLoading(false);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Customer Returns
            </Typography>
            <div className="filter-div"></div>
          </Box>
        )}
      </div>

      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                style={{
                  width: "240px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size="small"
                placeholder="Search"
                onChange={handlesearch}
              />
              <div style={{ display: "flex" }}>
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    getdoc();
                  }}
                  className="table-head-buttons"
                >
                  Print All Labels
                </button>
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    getdocorders();
                  }}
                  className="table-head-buttons"
                >
                  Print All Returns
                </button>

                <div
                  ref={ref}
                  style={{
                    display: "none",
                  }}
                >
                  {props.orderData &&
                    props.orderData.map((row, i) => {
                      return (
                        <table
                          id={"a" + i.toString()}
                          style={{
                            marginLeft: "10px",
                            marginTop: "10px",
                            border: "1px solid black",
                            borderCollapse: "collapse",
                            width: "280px",
                          }}
                        >
                          <tr
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                            }}
                          >
                            <td
                              style={{
                                verticalAlign: "top",
                                textAlign: "left",
                                padding: "10px",
                                fontWeight: 700,
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              Customer : {row.orderId?.recipientName}
                            </td>
                          </tr>

                          <tr
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                            }}
                          >
                            <td
                              style={{
                                verticalAlign: "top",
                                textAlign: "left",
                                padding: "10px",
                                fontWeight: 700,
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              Mobile no. : {row.orderId?.recipientPhoneNo}
                            </td>
                          </tr>

                          <tr
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                            }}
                          >
                            <td
                              style={{
                                verticalAlign: "top",
                                textAlign: "left",
                                padding: "10px",
                                fontWeight: 700,
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              Return ID : {row.returnId}
                            </td>
                          </tr>

                          <tr
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                              height: "193px",
                            }}
                          >
                            <td
                              style={{
                                verticalAlign: "top",
                                textAlign: "left",
                                padding: "10px",
                                fontWeight: 700,
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              Delivery Address :{" "}
                              {row.orderId?.addressLine ? (
                                <>{row.orderId.addressLine}</>
                              ) : (
                                <>No Address</>
                              )}
                            </td>
                          </tr>
                        </table>
                      );
                    })}

                  {props.orderData &&
                    props.orderData.map((row, i) => {
                      return (
                        <table
                          id={"b" + i.toString()}
                          style={{
                            border: "1px solid black",
                            borderCollapse: "collapse",
                            height: "800px",
                            width: "580px",
                            marginLeft: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <tr
                            style={{
                              height: "10px",
                            }}
                          >
                            <td
                              colSpan={4}
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                padding: "10px",
                                fontSize: "12px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                }}
                              >
                                {" "}
                                RAJDHANI RETAIL
                              </span>{" "}
                              <br></br>
                              GROUND FLOOR, PARINAY MANGAL KARYALAYA, MANIKBAUG,
                              SINHAGAD RD PUNE-53 PH NO. - 8378909909
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                              colSpan={7}
                            >
                              Return NO. {row.returnId} <br></br>
                              DATE {row.createdAt.toString().substring(0, 10)}
                            </td>
                          </tr>

                          <tr
                            style={{
                              height: "10px",
                            }}
                          >
                            <td
                              colSpan={3}
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                                width: "30%",
                              }}
                            >
                              Customer {row.orderId?.recipientName} <br></br>
                              Mobile No. {row.orderId?.recipientPhoneNo}
                            </td>
                            <td
                              colSpan={1}
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                            >
                              Return Reason <br></br>
                              {row.message}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                              colSpan={7}
                            >
                              Delivery Address <br></br>
                              {row.orderId?.addressLine ? (
                                <>{row.orderId.addressLine}</>
                              ) : (
                                <>No Address</>
                              )}
                            </td>
                          </tr>

                          <tr
                            style={{
                              height: "10px",
                            }}
                          >
                            <th
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                                textAlign: "center",
                              }}
                            >
                              Sr. No
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                                textAlign: "center",
                              }}
                            >
                              uniqueId
                            </th>
                            <th
                              colSpan={3}
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                            >
                              {" "}
                              Product Name
                            </th>
                            <th
                              colSpan={2}
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                            >
                              Rate
                            </th>
                            <th
                              colSpan={2}
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                            >
                              Quantity{" "}
                            </th>
                            <th
                              colSpan={3}
                              style={{
                                padding: "10px",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                            >
                              Total{" "}
                            </th>
                          </tr>

                          {row &&
                            row.productList.map((element, i) => {
                              return (
                                <tr
                                  style={{
                                    height: "20px",
                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "1px solid black",
                                      textAlign: "left",
                                      fontSize: "12px",
                                      verticalAlign: "top",
                                      width: "10%",
                                    }}
                                  >
                                    {i + 1}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "1px solid black",
                                      textAlign: "left",
                                      fontSize: "12px",
                                      verticalAlign: "top",
                                      width: "10%",
                                    }}
                                  >
                                    {element?.productId?.rwId}
                                  </td>
                                  <td
                                    colSpan={3}
                                    style={{
                                      padding: "10px",
                                      borderRight: "1px solid black",
                                      textAlign: "left",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {" "}
                                    {element.productId?.name}
                                  </td>
                                  <td
                                    colSpan={2}
                                    style={{
                                      borderRight: "1px solid black",
                                      textAlign: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {" "}
                                    {element.productId?.price}
                                  </td>
                                  <td
                                    colSpan={2}
                                    style={{
                                      borderRight: "1px solid black",
                                      textAlign: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {element.variant ? (
                                      element.variant >= 100 ? (
                                        <>
                                          {" "}
                                          {element.variant}
                                          gm
                                        </>
                                      ) : (
                                        <>
                                          {element.variant}
                                          kg
                                        </>
                                      )
                                    ) : (
                                      <>
                                        {element.count}
                                        pcs{" "}
                                      </>
                                    )}
                                  </td>
                                  <td
                                    colSpan={3}
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {" "}
                                    {element.variant ? (
                                      element.variant >= 100 ? (
                                        <>
                                          {" "}
                                          {element.productId?.price *
                                            element.count *
                                            (element.variant / 1000)}
                                        </>
                                      ) : (
                                        <>
                                          {element.productId?.price *
                                            element.count *
                                            element.variant}
                                        </>
                                      )
                                    ) : (
                                      <>
                                        {" "}
                                        {element.productId?.price *
                                          element.count}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}

                          <tr>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "right",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                              }}
                              colSpan={11}
                            ></td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "right",
                                padding: "10px",
                                fontSize: "12px",
                                verticalAlign: "top",
                                fontWeight: 700,
                              }}
                              colSpan={11}
                            >
                              Total:₹
                              {row.returnAmount}
                            </td>
                          </tr>
                        </table>
                      );
                    })}
                </div>
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ReturnTable = ({ returns, orderstatus, functionchange }) => {
  const [order_type, setOrder_type] = useState("");
  // console.log(getOrder);

  const navigate = useNavigate();
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [orderData, setOrderData] = useState();
  // setOrderData([...getOrder])
  const [oriorderData, setOriOrderData] = useState([]);
  // console.log(getOrder);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [products_data, setProducts_data] = useState([]);

  const [product, setProduct] = useState(0);
  const [users, setUsers] = useState([]);
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    // setOrderstart()
    // setOrderData(orderData.filter( (item) => item.status === orderstatus))
    if (orderstatus !== "all") {
      const filteredorders = returns.filter(
        (item) => item.status === orderstatus,
      );
      setOrderData([...filteredorders].reverse());
    } else setOrderData([...returns].reverse());
  }, [returns]);

  useEffect(() => {
    // console.log(orderData)
  }, [orderData, orderstatus]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 10) : 0;

  const tableRef = useRef(null);
  const [Edit, setEdit] = useState({});
  const [trackingID, setTrackingID] = useState({});

  const handleEdit = (key, value) => {
    setEdit({ ...Edit, [key]: value });
  };
  const [status, setStatus] = useState({});

  const handleStatus = async (value, id, userId) => {
    setLoading(true);
    //api to change status
    try {
      const updatestatus = await axios.put(
        `${server}/admin/returns/update/${id}`,
        {
          status: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      //send notificaton acc to status update
      let message;
      switch (value) {
        case "inprogress":
          message = "We are on our way to pick up the products";
          break;

        case "completed":
          message = "Requested products have been succesfully returned";
          break;

        case "cancelled":
          message = "Return request has been cancelled";
          break;
      }

      const notification = await axios.post(
        `${server}/notifications/send`,
        {
          userId: userId,
          returnId: id,
          message: message,
          type: "return",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      functionchange();
    } catch (error) {
      console.log(error);
    }

    setLoading(false);

    // navigate("/content")
  };
  return (
    <div className="order-table-main">
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            product={product}
            setProduct={setProduct}
            products_data={products_data}
            tableRef={tableRef}
            orderData={orderData}
            status={orderstatus}
            setOrderData={setOrderData}
            oriorderData={returns}
            order_type={order_type}
            setOrder_type={setOrder_type}
            setLoading={setLoading}
          />

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              // aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              exportButton={true}
              ref={tableRef}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                orderData={orderData}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={orderData?.length}
              />
              <TableBody>
                {(rowsPerPage > 0
                  ? orderData?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : orderData
                )?.map((row, index) => {
                  // handleEdit(index,false);
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  {
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => {
                        //   event.preventDefault()
                        //   navigate(`/admin/viewreturn/${row._id}`)
                        // }}
                        // role="checkbox"
                        // aria-checked={isItemSelected}

                        tabIndex={-1}
                        key={row._id}
                        // selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        className={"row" + index}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                          sx={{ minWidth: "10rem" }}
                        >
                          {
                            // <GetUserDetail id={row.order.user} />

                            <Link
                              to={`/admin/viewreturn/${row._id}`}
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  fontSize: "14px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                  }}
                                >
                                  User ID:{" "}
                                  {row?.userId?._id
                                    ?.toString()
                                    ?.substring(0, 6) ?? "null"}{" "}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                  }}
                                >
                                  User Name: {row.orderId?.recipientName}{" "}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                  }}
                                >
                                  Phone No.: {row.orderId?.recipientPhoneNo}{" "}
                                </Typography>
                              </Box>
                            </Link>
                          }
                        </TableCell>
                        <TableCell align="left">
                          {row.deliveryAddressId === null ? (
                            <>Address Deleted</>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {row.orderId?.addressLine ? (
                                <>
                                  {" "}
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    Location Type: {row.orderId?.locationType}{" "}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    Address Line: {row.orderId?.addressLine}{" "}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                    }}
                                  >
                                    PinCode: {row.orderId?.pincode}{" "}
                                  </Typography>
                                </>
                              ) : (
                                <>No Address</>
                              )}
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                              }}
                            >
                              Date:{" "}
                              {format(
                                new Date(row.createdAt),
                                "dd MMMM yyyy - hh:mm a",
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                              }}
                            >
                              Return Total: ₹{row.returnAmount?.toFixed(2)}{" "}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                              }}
                            >
                              Return Id: {row.returnId}{" "}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {row.message ? (
                            <Typography
                              sx={{
                                fontSize: "14px",
                              }}
                            >
                              {row.message}{" "}
                            </Typography>
                          ) : (
                            <>None</>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <select
                            defaultValue={row?.status}
                            className="table-action-select"
                            onChange={(e) =>
                              handleStatus(e.target.value, row._id, row.userId)
                            }
                          >
                            <option value={"pending"}>Pending</option>
                            <option value={"inprogress"}>In Progress</option>
                            <option value={"completed"}>Completed</option>
                            <option value={"cancelled"}>Cancelled</option>
                          </select>
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                      </TableRow>
                    );
                  }
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={orderData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
      </Box>
    </div>
  );
};

export default ReturnTable;
