import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  // const navigate = useNavigate()

  return localStorage.getItem("user") && !localStorage.getItem("adminuser") ? (
    children
  ) : (
    <Navigate to="/home" />
  );
};

export default PrivateRoute;
