import axios from "axios";
import { useContext, useEffect, useState } from "react";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
import AllOrders from "../Dashboard/Layout/AllOrders";
// import * as React from 'react';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import ReturnTable from "./Layout/ReturnTable";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminReturns = () => {
  const [returns, setReturns] = useState();
  const [customerByOrder, setcustomerByOrder] = useState([]);
  const [origetOrder, setOriGetOrder] = useState([]);
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dataOrders = async () => {
    // let orders=[];
    setReturns([]);
    setLoading(true);
    const res = await axios.get(`${server}/returns`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(res.data)
    setReturns(res.data);
    //  setOriGetOrder([...res.data])
    setLoading(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
    else dataOrders();
  }, []);

  useEffect(() => {
    // console.log(returns)
  }, [value, returns]);

  return (
    <div>
      <div className="order-head">
        <p className="order-title">Returns Summary</p>
      </div>
      {/* <div className="order-grid">
        <div className="allorders"> */}
      {/* this is the tab showing count in dashboard folder common */}
      {/* <AllReturns orders={returns} />
        </div>
        <div className="canceled">
          <Canceled orders={returns} />
        </div> */}
      {/* <div className="abandoned">
        <AllReturns orders={ returns } /> 
        </div> */}
      {/* </div> */}
      <div className="allorders">
        {" "}
        <AllOrders orders={returns} type={"Returns"} />{" "}
      </div>

      {returns ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Pending" {...a11yProps(0)} />
              <Tab label="In Progress" {...a11yProps(1)} />
              <Tab label="Completed" {...a11yProps(2)} />
              <Tab label="Cancelled" {...a11yProps(3)} />
              <Tab label="All Returns" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <ReturnTable
              returns={returns}
              orderstatus="pending"
              functionchange={dataOrders}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ReturnTable
              returns={returns}
              orderstatus="inprogress"
              functionchange={dataOrders}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ReturnTable
              returns={returns}
              orderstatus="completed"
              functionchange={dataOrders}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <ReturnTable
              returns={returns}
              orderstatus="cancelled"
              functionchange={dataOrders}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <ReturnTable
              returns={returns}
              orderstatus="all"
              functionchange={dataOrders}
            />
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={2}>
          <ReturnTable returns={ returns } orderstatus = "pending" />
          </CustomTabPanel> */}
        </Box>
      ) : (
        <></>
      )}
      {/* <div>
        <OrderTable getOrder={getOrder} />
      </div> */}
    </div>
  );
};

export default AdminReturns;
