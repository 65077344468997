import {
  Box,
  Checkbox,
  IconButton,
  InputBase,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";

import axios from "axios";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import DefaultImage from "../../../../assets/defaultimg.jpg";
import server from "../../../server";

const mainCardDiv = {
  display: "flex",
  borderBottom: "2px solid #F0F0F0",
  marginTop: "10px",
  padding: "10px",
  alignItems: "center",
};

const InventoryInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: "#000000",
    marginTop: "10px",
  },
  "& .MuiInputBase-input": {
    color: "#808080",
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    border: "1px solid #E0E0E0",
    fontSize: "12px",
    padding: "0px 5px",
  },
}));

const cardTitle = {
  color: "#37474F",
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Quicksand",
};

const priceText = {
  fontWeight: "600",
  color: "rgba(55, 71, 79, 0.54);",
  textDecoration: "line-through",
  margin: "0",
  fontSize: "16px",
  fontFamily: "Quicksand",
};

const salePriceText = {
  color: "#F37A20",
  fontWeight: "600",
  margin: "0",
  fontSize: "22px",
  fontFamily: "Quicksand",
};

const salePercentage = {
  fontFamily: "quicksand",
  color: "#fff",
  backgroundColor: "#F37A20",
  borderRadius: "50%",
  position: "absolute",
  padding: "5px 12px",
  fontSize: "14px",
  top: "0",
  left: "10px",
};

const buttonPlusMinus = {
  padding: "8px 18px",
  color: "#fff",
};

const ReturnOrderCard = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [productinfo, setproductinfo] = useState([]);
  const [variants, setVariants] = useState();
  const [checked, setChecked] = useState(false);
  const [changingquantity, setchangingquantity] = useState(props.quantity);

  const fetchProductDetails = async () => {
    try {
      const result = await axios.get(`${server}/products/${props.prodid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      // console.log(result.data)
      setproductinfo(result.data.product);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      // if (result.data.variants) setvariantinfo(result.data.variants)
    } catch (error) {
      if (error.response.data.message === "Product not found") {
        //get from variant collection
        // console.log(" it is a variant ")
        getVariants();
      }
    }
  };

  const getVariants = async () => {
    const result = await axios.get(
      `${server}/products/variant/${props.prodid}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setVariants(result.data.variantinfo[0]);
    const productresult = await axios.get(
      `${server}/products/${result.data.variantinfo[0].productId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(productresult.data.product)
    // console.log(result.data.variantinfo[0])
    // setQuantity(parseInt(props.proddetail))
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const changequantityforreturn = (e, i) => {
    e.preventDefault();

    if (i == 0) {
      //minus
      if (changingquantity === 1) {
        setChecked(false);
      }
      if (changingquantity === 0) return;
      setchangingquantity((current) => current - 1);
      // if (!variants) props.quantchangefunction(checked, i, productinfo.price, productinfo._id)
      // else props.quantchangefunction(checked, i, variants.price, variants._id)
      if (props.variant) {
        if (props.variant >= 100) {
          props.quantchangefunction(
            checked,
            i,
            productinfo.price * (props.variant / 1000),
            productinfo._id,
            props.variant,
          );
        } else {
          props.quantchangefunction(
            checked,
            i,
            productinfo.price * props.variant,
            productinfo._id,
            props.variant,
          );
        }
      } else
        props.quantchangefunction(
          checked,
          i,
          productinfo.price,
          productinfo._id,
          props.variant,
        );
    } else {
      if (changingquantity === props.quantity) {
        toast.warning("you only bought " + props.quantity);
        return;
      }
      setchangingquantity((current) => current + 1);

      if (props.variant) {
        if (props.variant >= 100) {
          props.quantchangefunction(
            checked,
            i,
            productinfo.price * (props.variant / 1000),
            productinfo._id,
            props.variant,
          );
        } else {
          props.quantchangefunction(
            checked,
            i,
            productinfo.price * props.variant,
            productinfo._id,
            props.variant,
          );
        }
      } else
        props.quantchangefunction(
          checked,
          i,
          productinfo.price,
          productinfo._id,
          props.variant,
        );
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, []);

  useEffect(() => {
    if (props.variant) {
      if (props.variant >= 100) {
        props.calculate(
          checked,
          productinfo.price * (props.variant / 1000),
          changingquantity,
          productinfo._id,
          props.variant,
        );
      } else {
        props.calculate(
          checked,
          productinfo.price * props.variant,
          changingquantity,
          productinfo._id,
          props.variant,
        );
      }
    } else
      props.calculate(
        checked,
        productinfo.price,
        changingquantity,
        productinfo._id,
        props.variant,
      );
  }, [checked]);

  // useEffect(() => {

  // }, [checked])

  useEffect(() => {}, [changingquantity, isLoading]);

  const handleChange = (e) => {
    // e.preventDefault()
    // console.log(checked)
    setChecked(!checked);
    // console.log('new chechked ' + checked)
  };

  return (
    <Box>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
        // style={ { width:'40%'}}
      />

      {/* <Link to="/productdetail" style={{ textDecoration: "none" }}> */}
      <Box sx={mainCardDiv}>
        <Box>
          <Checkbox checked={checked} onChange={(e) => handleChange(e)} />
        </Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            wdith: "50%",
          }}
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width={130} height={118}></Skeleton>
          ) : (
            <>
              <img
                src={
                  productinfo.imageUrl === "noimage"
                    ? DefaultImage
                    : productinfo.imageUrl
                }
                alt="product-images"
                style={{
                  // width: "130px",
                  // flex: "25",
                  maxWidth: "30vw",
                  minWidth: "20vw",
                  // flex: "25",
                  maxHeight: "50vh",
                }}
              />
              <Typography sx={salePercentage}>
                {parseInt((productinfo.price / productinfo.mrpPrice) * 100)}%
                <br></br> Off
              </Typography>
            </>
          )}
        </Box>

        <Box sx={{ flex: "50", padding: "0px 10px" }}>
          {isLoading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: "16px", width: "100px" }}
            />
          ) : (
            <Typography sx={cardTitle}>{productinfo.name}</Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {props.variant ? (
                <>
                  <Typography>
                    Weight :{" "}
                    {props.variant >= 100 ? (
                      <>{props.variant}gm</>
                    ) : (
                      <>{props.variant}kg</>
                    )}
                  </Typography>
                  {props.variant >= 100 ? (
                    <Typography sx={salePriceText}>
                      ₹
                      {(productinfo.price * (props.variant / 1000))?.toFixed(2)}
                    </Typography>
                  ) : (
                    <Typography sx={salePriceText}>
                      ₹{(productinfo.price * props.variant)?.toFixed(2)}
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Typography sx={priceText}>
                    ₹{productinfo.mrpPrice?.toFixed(2)}
                  </Typography>
                  <Typography sx={salePriceText}>
                    ₹{productinfo.price?.toFixed(2)}
                  </Typography>
                </>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // backgroundColor: "#F1F1F1",
                  borderRadius: "7px",
                  backgroundColor: "#5EC401",
                  color: "#fff",
                }}
              >
                <IconButton
                  sx={buttonPlusMinus}
                  onClick={(e) => changequantityforreturn(e, 0)}
                >
                  -
                </IconButton>
                {changingquantity}
                <IconButton
                  sx={buttonPlusMinus}
                  onClick={(e) => changequantityforreturn(e, 1)}
                >
                  +
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* </Link> */}
    </Box>
  );
};

export default ReturnOrderCard;
