import { Button } from "@mui/material";
import * as XLSX from "xlsx";

const DownloadSampleExcel = () => {
  // Function to handle the download of the sample Excel file
  const handleDownload = () => {
    // Define the headings for the Excel file
    const headings = [
      [
        "brandId",
        "categoryId",
        "subCategoryId",
        "name",
        "price",
        "mrpPrice",
        "stock",
        "sales",
        "description",
        "buyinglimit",
        "stocklimit",
        "published",
        "barcode",
      ],
    ];

    // Create a new worksheet with the headings
    const worksheet = XLSX.utils.aoa_to_sheet(headings);

    // Create a new workbook and append the worksheet to it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");

    // Generate binary data for the workbook
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the binary data
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to a URL created from the Blob
    link.href = URL.createObjectURL(blob);

    // Set the download attribute with the desired file name
    link.download = "sample_product_info.xlsx";

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);
  };

  return (
    <div>
      {/* Button to trigger the download */}
      <Button size="small" color="primary" onClick={handleDownload}>
        Sample Excel
      </Button>
    </div>
  );
};

export default DownloadSampleExcel;
