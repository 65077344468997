const Sales = (props) => {
  const orders = props.orders;
  const returns = props.returns;
  let c = 0;
  let d = 0;
  let cartSize = 0;
  orders?.map((item) => {
    c += item?.totalAmount;
    cartSize += item?.productList.length;
    d++;
  });
  returns?.map((item) => {
    c -= item?.returnAmount;
  });
  // setActive(c);
  return (
    <div className="abandoned-main">
      {/* <div className="abandoned-div">
        <img alt="cart-img" src={BagBlack} className="cart-image" /> */}
      {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      {/* </div> */}
      <div className="abandoned-body">
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Net Sales</p>
          <p className="cart-percentage">₹ {c}</p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Average Order Value (AOV)</p>
          <p className="cart-percentage">₹ {(c / d).toFixed(2)}</p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Average Profit Per Customer</p>
          <p className="cart-percentage">
            ₹ {(c / props.customerCount).toFixed(2)}
          </p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Avg Cart Size</p>
          <p className="cart-percentage">{(cartSize / d).toFixed(2)}</p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Sales Volume</p>
          <p className="cart-percentage">{d}</p>
        </div>
        {/* <div className="abandoned-body-item">
          <p className="abandoned-body-title">Completed</p>
          <p className="cart-percentage">
            {c}<span>+0.00%</span>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Sales;
