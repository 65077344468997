import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  Modal,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { ReactComponent as Bag } from "../../../../assets/cart.svg";
import { ReactComponent as Cart } from "../../../../assets/shoppin-cart.svg";
import { ReactComponent as Star } from "../../../../assets/Star.svg";

import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useEffect } from "react";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultImage from "../../../../assets/defaultimg.jpg";
import { weightvariants } from "../../../../assets/variants";
import { topRightToastStyles } from "../../../../constants/styles";
import server from "../../../server";
import { NavBarContext } from "../../Navbar/Navbar";

const buttonPlusMinus = {
  padding: "10px 18px",
  color: "#fff",
  fontFamily: "quicksand",
  fontWeight: 500,
  fontSize: "14px",
};

const ProductCardDiv = {
  position: "relative",
  background: "#fff",
  border: "1px solid rgba(173, 173, 173, 0.25) ",
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
  borderRadius: "3px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  zIndex: 1,
  padding: "10px",
  width: "40vw",
  height: "95%",
};

const SaleText = {
  backgroundColor: "#FF4F4F",
  color: "#fff",
  padding: "3px 10px",
  // width: "40%",
  borderRadius: "3px 0px",
  position: "absolute",
  top: "0",
  left: "0",
};

const ViewCategoryText = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "16px",
  // margin: "10px 0px",
};

const StarDiv = {
  display: "flex",
  flexDirection: "row",
  // alignItems: "center",
  // margin: "5px 0px",
};

const StarDivText = {
  color: "#ADADAD",
  textAlign: "center",
  fontSize: "12px",
  display: "flex",
  // flexWrap: "wrap",
  alignItems: "center",
  margin: "2px",
};

const ProductPrice = {
  fontFamily: "quicksand",
  color: "#F37A20",
  fontWeight: "600",
  // width: "auto",
  // maxWidth: "20px"
  fontSize: "18px",
};

const ProductPriceSale = {
  fontFamily: "quicksand",
  color: "#ADADAD",
  fontWeight: "600",
  fontSize: "14px",

  margin: "0px 10px",
  textDecoration: "line-through",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  fontFamily: "Quicksand",
  fontWeight: 500,
  textTransform: "capitalize",
  padding: "10px 10px",
  fontSize: "14px",
  width: "100%",
  position: "absoulte",
  bottom: "0",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  height: "auto",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
  "&.Mui-disabled": {
    // background: "#eaeaea",
    color: "#fff",
  },
};

const InventoryInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    color: "#000000",
    marginTop: "10px",
  },
  "& .MuiInputBase-input": {
    color: "#808080",
    backgroundColor: "#ffffff",
    borderRadius: "3px",
    border: "1px solid #E0E0E0",
    fontSize: "12px",
    padding: "0px 5px",
  },
}));

const ProductCard = (props) => {
  //context
  const items = useContext(NavBarContext);

  const [rating, setRating] = useState(0);

  const [variants, setVariants] = useState([]);
  // const [properties, setProperties] = useState([])
  const [selectedVariant, setselectedVariant] = useState(1);

  const [cartdetails, setCartDetails] = useState([]);
  const [quantitybutton, setQuantityButton] = useState(false);
  //quantity is an int
  const [quantity, setQuantity] = useState();
  const [stars, setStars] = useState([]);

  const [weights, setWeights] = useState(weightvariants.weights);

  //modal states
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const changequantity = (e, i) => {
    e.preventDefault();

    // console.log('quant change')
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    //jult 22 code below
    if (i == 1) {
      //plus button
      // props.proddetail[1] = 6
      if (
        props.proddetails.buyinglimit === quantity &&
        props.proddetails.buyinglimit !== 0
      ) {
        toast.warning("Buying Limit Exceeded", topRightToastStyles);
        return;
      }

      setQuantity((current) => current + 1);
      //change in localstorage also

      //check if variant or product FIRST then proceed
      //it is a product not a variant
      const exists = cartarr.some(function (element) {
        if (element[0] == props.proddetails._id) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) + 1).toString();
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  props.proddetails.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  props.proddetails.price * selectedVariant,
              );
          } else {
            element[1] = (parseInt(element[1]) + 1).toString();
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                props.proddetails.price,
            );
          }
          return true;
        }
      });

      //incremented toast
      // toast.success("Quantity Increased", {
      //   position: "top-right",
      //   autoClose: 80,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    } else {
      setQuantity((current) => current - 1);

      //it is a product not a variant
      const exists = cartarr.some(function (element) {
        if (element[0] == props.proddetails._id) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) - 1).toString();

            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) -
                  props.proddetails.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) -
                  props.proddetails.price * selectedVariant,
              );

            if (element[2] == 0) {
              //remove from cart
              items.setItems((current) => current - 1);
              setQuantityButton(false);
              return false;
            }
          } else {
            element[1] = (parseInt(element[1]) - 1).toString();
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) -
                props.proddetails.price,
            );
            if (element[1] == 0) {
              //remove from cart
              setQuantityButton(false);
              items.setItems((current) => current - 1);
              return false;
            }
          }
          return true;
        }
      });

      // toast.success("Quantity Decreased", {
      //   position: "top-right",
      //   autoClose: 80,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
    localStorage.setItem("cart", JSON.stringify(cartarr));
  };

  const getreview = async () => {
    try {
      // console.log(items)
      const result = await axios.get(
        `${server}/products/${props.proddetails._id}/reviews`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(result.data)
      if (result.data.length !== 0) {
        let avg = 0;
        result.data.forEach((review) => (avg += review.rating));
        avg = avg / result.data.length;
        // console.log("avg is " + avg)
        setRating(avg);
        setStars(Array(parseInt(avg)).fill(0));
      } else {
        // setRating(0)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVariants = () => {
    //new variant logic 22july

    let cartarr = JSON.parse(localStorage.getItem("cart"));

    cartarr.some(function (element) {
      if (element[0] == props.proddetails._id) {
        if (element.length == 3) {
          if (parseInt(element[2]) !== 0) {
            setQuantityButton(true);
            setQuantity(parseInt(element[2]));
            setselectedVariant(element[1].toString());
            // console.log("changed variant")
          }
        } else {
          if (parseInt(element[1]) !== 0) {
            setQuantityButton(true);
            setQuantity(parseInt(element[1]));
          }
        }
      }
    });
  };

  const addtocart = async (e) => {
    e.preventDefault();

    // july 22 code below
    let cartarr = JSON.parse(localStorage.getItem("cart"));
    if (localStorage.getItem("cart")) {
      //check for prexisting product
      const exists = cartarr.some(function (element) {
        if (element[0] == props.proddetails._id) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) + 1).toString();
            element[1] = parseInt(selectedVariant);

            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  props.proddetails.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  props.proddetails.price * selectedVariant,
              );
          } else {
            element[1] = (parseInt(element[1]) + 1).toString();
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                props.proddetails.price,
            );
          }
          // console.log(element[1])
          setQuantityButton(true);
          setQuantity(1);
          return true;
        }
      });
      //increment the quantity

      //update the cart
      if (!exists) {
        let temparr;
        if (props.proddetails.name?.toLowerCase()?.includes("loose")) {
          temparr = [props.proddetails._id, selectedVariant, 1];
          if (selectedVariant >= 100) {
            // divide by 1000
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                props.proddetails.price * (selectedVariant / 1000),
            );
          } else
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                props.proddetails.price * selectedVariant,
            );
        } else {
          temparr = [props.proddetails._id, 1];
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              props.proddetails.price,
          );
        }

        cartarr.push(temparr);
        setQuantity(1);
        setQuantityButton(true);
      }
    }

    localStorage.setItem("cart", JSON.stringify(cartarr));

    items.setItems((current) => current + 1);

    toast.success("Added to bag.", topRightToastStyles);
  };

  const displaybutton = () => {
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    setQuantityButton(false);

    //july 22 code below
    cartarr.some(function (element) {
      if (element[0] == props.proddetails._id) {
        // console.log('changing button' + element[1])
        if (element.length === 3) {
          if (parseInt(element[2]) !== 0) {
            setQuantityButton(true);
            setQuantity(parseInt(element[2]));
            // setselectedVariant(element[1].toString())
          }
        } else {
          if (parseInt(element[1]) !== 0) {
            setQuantityButton(true);
            setQuantity(parseInt(element[1]));
          }
        }
      }
      // else setQuantityButton(false)
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    //meaning old variant has some non zero quantity in cart
    //turn it to zero first
    let cartarr = JSON.parse(localStorage.getItem("cart"));

    //july 22 code below
    const exists = cartarr.some(function (element) {
      if (element[0] == props.proddetails._id) {
        if (element[1] >= 100) {
          // divide by 1000
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) -
              props.proddetails.price * (element[1] / 1000) * element[2],
          );
        } else
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) -
              props.proddetails.price * element[1] * element[2],
          );

        element[1] = e.target.value;

        if (element[1] >= 100) {
          // divide by 1000
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              props.proddetails.price * (element[1] / 1000) * element[2],
          );
        } else
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) +
              props.proddetails.price * element[1] * element[2],
          );

        return true;
      }

      // else setQuantityButton(false)
    });

    localStorage.setItem("cart", JSON.stringify(cartarr));

    setselectedVariant(e.target.value);
  };
  useEffect(() => {
    getVariants();
    // getreview();
  }, []);

  useEffect(() => {
    displaybutton();
  }, [variants]);

  useEffect(() => {
    // console.log(rating)
  }, [quantitybutton, quantity, weights, rating, stars, items.items]);

  useEffect(() => {
    // console.log(selectedVariant + " " + props.proddetails._id)
  }, [selectedVariant]);

  return (
    // <NavBarContext.Provider value = { items }>
    <Box sx={ProductCardDiv}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            // top: '30vh',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            width: "100vw",
            // height: "70vh",
            bgcolor: "background.paper",
            borderTopLeftRadius: "5%",
            borderTopRightRadius: "5%",

            // borderRadius: "5%",
            boxShadow: 24,
            // padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "quicksand",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              Choose an available size
            </Typography>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ overflow: "scroll" }}>
            <FormControl sx={{ width: "100%" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(e) => handleChange(e)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    overflow: "scroll",
                  }}
                >
                  {weights &&
                    weights.map((currweight, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            width: "40%",
                            border: "1px solid #EAEAEA",
                            padding: "5px",
                            marginBottom: "5px",
                            // maxHeight:"10vh"
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "quicksand",
                                fontWeight: 600,
                                fontSize: "14px",
                              }}
                            >
                              {" "}
                              {currweight >= 100 ? (
                                <>
                                  {currweight}
                                  gm
                                </>
                              ) : (
                                <>
                                  {currweight}
                                  kg
                                </>
                              )}{" "}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "quicksand",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  color: "rgba(243, 122, 32, 1)",
                                }}
                              >
                                ₹{" "}
                                {currweight >= 100 ? (
                                  <>
                                    {" "}
                                    {(
                                      props.proddetails?.price *
                                      (currweight / 1000)
                                    ).toFixed(2)}{" "}
                                  </>
                                ) : (
                                  <>{props.proddetails?.price * currweight}</>
                                )}
                              </Typography>
                              <FormControlLabel
                                value={currweight}
                                control={<Radio />}
                                sx={{
                                  margin: "0px",
                                }}
                              />
                            </Box>
                          </Box>
                        </div>
                      );
                    })}
                  <Box sx={{ width: "40%", padding: "5px" }}></Box>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "stretch",
              width: "100%",
            }}
          >
            <Button
              sx={ButtonCart}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleClose();
                addtocart(e);
              }}
            >
              <Box></Box>
              <Typography> Add to Bag</Typography>

              <Bag
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "14px",
                  height: "14px",
                  margin: "-3px 5px 0px 0px",
                }}
              />
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* {100 -
				parseInt(props.proddetails.price / props.proddetails.mrpPrice) *
					100 >
			0 ? (
				<Typography variant="body2" sx={SaleText}>
					Save{" "}
					{100 -
						parseInt(
							(props.proddetails.price /
								props.proddetails.mrpPrice) *
								100
						)}{" "}
					%OFF
				</Typography>
			) : (
				<></>
			)} */}
      {parseInt(props.proddetails.mrpPrice - props.proddetails.price) > 0 ? (
        <Typography variant="body2" sx={SaleText}>
          Save ₹{parseInt(props.proddetails.mrpPrice - props.proddetails.price)}
        </Typography>
      ) : (
        <></>
      )}
      {/* problem with a link here. need to check */}
      <Box style={{ maxWidth: "200px", height: "200px" }}>
        <Link to={`/productdetail/${props.proddetails._id}`}>
          <img
            src={
              props.proddetails.imageUrl === "noimage"
                ? DefaultImage
                : props.proddetails.imageUrl
            }
            alt="ProductImage"
            className="client-product-image"
            style={{ width: "100%", height: "100%" }}
          />
        </Link>
      </Box>

      <Typography variant="subtitle1" sx={ViewCategoryText}>
        {props.proddetails.name}
      </Typography>
      <Box sx={StarDiv}>
        <Box className="star-div">
          {stars &&
            stars.map((element, i) => {
              return <Star className="star-item" />;
            })}
          {rating % 1 === 0 ? <></> : <Star className="star-item" />}
        </Box>
        <Typography sx={StarDivText}>({rating.toFixed(1)})</Typography>
      </Box>

      {props.proddetails.name?.toLowerCase()?.includes("loose") ? (
        <FormControl size="small" sx={{ margin: "10px 0px" }}>
          <Box
            sx={{
              // width: "auto",
              border: "1px solid #EAEAEA",
              padding: "2px",
            }}
          >
            {selectedVariant >= 100 ? (
              <>{selectedVariant}gm</>
            ) : (
              <>{selectedVariant}kg</>
            )}
          </Box>

          {/* <Select
            sx={{ width: "40vw" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
            label="inventory" input={<InventoryInput />} value={selectedVariant} >
            {
              weights && weights.map((currweight, i) => {
                return <MenuItem value={currweight} key={i} name={i} >
                  {currweight >= 100 ? <>{currweight}gm</>
                    : <>{currweight}kg</>
                  }
                </MenuItem>
              })
            }
          </Select> */}
        </FormControl>
      ) : (
        <></>
      )}

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          margin: "0px 0px 10px 0px",
          // maxHeight:"50vh"
        }}
      >
        {props.proddetails.name?.toLowerCase()?.includes("loose") &&
        parseInt(props.proddetails.mrpPrice - props.proddetails.price) > 0 ? (
          selectedVariant >= 100 ? (
            <Typography sx={ProductPriceSale}>
              ₹{(props.proddetails.price * (selectedVariant / 1000)).toFixed(2)}
            </Typography>
          ) : (
            <Typography sx={ProductPriceSale}>
              ₹{(props.proddetails.price * selectedVariant).toFixed(2)}
            </Typography>
          )
        ) : (
          // {ProductPriceSale}
          <>
            {parseInt(props.proddetails.mrpPrice - props.proddetails.price) >
              0 && (
              <Typography sx={ProductPriceSale}>
                ₹{props.proddetails.mrpPrice?.toFixed(2)}
              </Typography>
            )}
            <Typography sx={ProductPrice}>
              ₹{props.proddetails.price?.toFixed(2)}
            </Typography>
          </>
        )}
      </Box>
      {quantitybutton ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F1F1F1",
            borderRadius: "7px",
            backgroundColor: "#5EC401",
            color: "#fff",
          }}
        >
          <IconButton
            sx={buttonPlusMinus}
            onClick={(e) => changequantity(e, 0)}
          >
            -
          </IconButton>
          {quantity}
          <IconButton
            sx={buttonPlusMinus}
            onClick={(e) => changequantity(e, 1)}
          >
            +
          </IconButton>
        </Box>
      ) : (
        <Button
          sx={ButtonCart}
          onClick={(e) => {
            e.preventDefault();
            if (props.proddetails.name?.toLowerCase()?.includes("loose")) {
              handleOpen();
            } else addtocart(e);
          }}
          disabled={
            props.proddetails.stocklimit &&
            props.proddetails.stocklimit > props.proddetails.stock
              ? true
              : false
          }
        >
          <Cart
            style={{
              margin: "0px 5px",
              height: "auto",
              width: "18px",
            }}
          />
          <Typography
            sx={{
              fontFamily: "quicksand",
              fontWeight: 500,
              fontSize: "14px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {props.proddetails.stocklimit &&
            props.proddetails.stocklimit > props.proddetails.stock ? (
              <>Out Of Stock</>
            ) : (
              <>Add to cart</>
            )}
          </Typography>
        </Button>
      )}
    </Box>
    // </NavBarContext.Provider>
  );
};

export default ProductCard;
