import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import server from "../../server";
import ProductNotFound from "../Search/Layout/ProductNotFound";
import SubCategoryCard from "./Layout/SubCategoryCard";

const CategoryTitle = {
  fontWeight: "400",
  fontFamily: "Poppins",
  fontSize: "20px",
  padding: "10px 20px",
};

const Productcard = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  overflow: "hidden",
};

const SubCategory = () => {
  const { catid } = useParams();

  const [subcategories, setSubCategories] = useState([]);
  const [catname, setname] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  //this actually fetches all sub cat under the given catid
  const fetchAllCategories = async () => {
    setLoading(true);
    try {
      const result = await axios.get(`${server}/subcategory/find/${catid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setSubCategories(result.data);
    } catch (err) {}

    try {
      const getcatname = await axios.get(`${server}/category/${catid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setname(getcatname.data.name);
    } catch (err) {}
    setLoading(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else fetchAllCategories();
  }, []);

  // useEffect(() => {}, [subcategories]);

  return (
    <Box sx={{ marginBottom: "100px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            // navigate(-1)
            navigate("/category");
          }}
        >
          <ArrowBackRoundedIcon />
        </IconButton>
        <Typography variant="h6" sx={CategoryTitle}>
          {catname}
        </Typography>
      </Box>

      <Box sx={Productcard}>
        <Grid container spacing={3} sx={{ padding: "10px 20px" }}>
          {subcategories.length === 0 ? (
            <Box sx={{ position: "absolute", top: "25%" }}>
              {" "}
              <ProductNotFound
                message={"No SubCategories to Display"}
                loading={loading}
              />
            </Box>
          ) : (
            // subcategories &&
            subcategories.map((currcategory, i) => {
              return currcategory.imageUrl != "noimage" ? (
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  key={currcategory._id}
                >
                  {/* //subcat name sub cat id and cat id */}
                  <Link
                    to={`/categoryproducts/${currcategory.name}/${currcategory._id}/${currcategory.categoryId}`}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <SubCategoryCard
                      name={currcategory.name}
                      imgsrc={currcategory.imageUrl}
                    />
                  </Link>
                </Grid>
              ) : null;
            })
          )}{" "}
        </Grid>
      </Box>
    </Box>

    //deleted static grids
  );
};

export default SubCategory;
