import DeleteIcon from "@mui/icons-material/Delete";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import { alpha, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import DashboardContext from "../../Context/DashboardContext";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// function createData(
//   name,
//   address,
//   phone,
//   basketSize,
//   orderTotal,
//   dateTime,
//   status
// ) {
//   return {
//     name,
//     address,
//     phone,
//     basketSize,
//     orderTotal,
//     dateTime,
//     status,
//   };
// }

// const rows = [
//   createData(
//     "Cupcake",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     "2",
//     "$2000.00",
//     "12 Aug 2022 - 12:25 am",
//     "Active"
//   ),
//   createData(
//     "Donut",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     "4",
//     "$2000.00",
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Eclair",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     "1",
//     "$2000.00",
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Frozen yoghurt",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     "3",
//     "$2000.00",
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),

//   createData(
//     "Gingerbread",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     "9",
//     "$2000.00",
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Honeycomb",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     8,
//     6.5,
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Ice cream sandwich",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     37,
//     4.3,
//     "12 Aug 2022 - 12:25 am",
//     "Active"
//   ),
//   createData(
//     "Jelly Bean",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     94,
//     0.0,
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "KitKat",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     65,
//     7.0,
//     "12 Aug 2022 - 12:25 am",
//     "Active"
//   ),
//   createData(
//     "Lollipop",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     98,
//     0.0,
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Marshmallow",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     81,
//     2.0,
//     "12 Aug 2022 - 12:25 am",
//     "Active"
//   ),
//   createData(
//     "Nougat",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     9,
//     37.0,
//     "12 Aug 2022 - 12:25 am",
//     "Delivered"
//   ),
//   createData(
//     "Oreo",
//     "Pune, Maharashtra",
//     "+91 9822221876",
//     63,
//     4.0,
//     "12 Aug 2022 - 12:25 am",
//     "Active"
//   ),
// ];

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "Customer Name",
    numeric: false,
    disablePadding: true,
    label: "Customer Name",
  },
  {
    id: "Pincode",
    numeric: false,
    disablePadding: true,
    label: "Pincode",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Customer Since",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  const handlesearch = async (e) => {
    // const search_data=[];

    if (e.target.value != "") {
      // console.log(e.target.value);
      // console.log(props.oriorderData);
      const filteredRows = await props.oriorderData.filter((row) => {
        return row?.name.toLowerCase()?.includes(e.target.value.toLowerCase());
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setcustomers(filteredRows);
    } else {
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setcustomers(props.oriorderData);
    }
  };

  const exportToCSV = () => {
    const users = props.customers;
    // Convert users array to CSV format
    const csvRows = [];
    // Get the headers
    const headers = Object.keys(users[0]);
    csvRows.push(headers.join(","));

    // Loop through the array and create CSV rows
    for (const user of users) {
      const values = headers.map((header) => {
        const escaped = ("" + user[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    // Create a blob from the CSV string
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // Create a link element and trigger a download
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "Users.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Customers
          </Typography>
        )}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip>
            <>
              {console.log("example is ", props)}
              <DownloadTableExcel
                filename="Customers Table"
                sheet="Customers"
                currentTableRef={props.tableRef.current}
              >
                <button className="table-head-buttons"> Export excel </button>
              </DownloadTableExcel>
              <button className="table-head-buttons" onClick={exportToCSV}>
                Export Complete excel
              </button>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  style={{
                    width: "240px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  placeholder="Search Customer"
                  onChange={handlesearch}
                />
                {/* <div style={{ display: "flex" }}>
                <div className="table-head-buttons">
                  <img alt="img" src={Vector} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Calender} />
                  <p className="filter-text">Filter</p>
                </div>
                <select className="table-head-buttons">
                  <option>Bulk Action</option>
                </select>
              </div> */}
              </div>
            </>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CustomerTable = (props) => {
  // console.log(props.customers);

  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customers, setcustomers] = useState(props.customers);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  useEffect(() => {
    // console.log(customers)
    setcustomers(props.customers);
  }, [props.customers]);

  useEffect(() => {
    // console.log(customers)
  }, [customers]);

  const tableRef = useRef(null);

  return (
    <div className="order-table-main">
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            customers={customers}
            setcustomers={setcustomers}
            oriorderData={props.customers}
            tableRef={tableRef}
          />
          <TableContainer style={{ padding: "0px 20px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              ref={tableRef}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                oriorderData={props.customers}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={customers?.length}
              />
              <TableBody>
                {(rowsPerPage > 0
                  ? customers?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : customers
                )?.map((row, index) => {
                  // const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // if(row?.is_active=="true")
                  // props.setActive_users(props.active_users+1);

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      // selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      style={{ border: "1px solid transparent" }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row.name ? <>{row.name}</> : <>No name</>}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row.pincode}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row.mobile}
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {format(
                          new Date(row.createdAt),
                          "dd MMMM yyyy - hh:mm a",
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 25]}
            component="div"
            count={customers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default CustomerTable;
