import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import server from "../../server";
import CategoryCard from "./Layout/CategoryCard";

const CategoryTitle = {
  fontWeight: "400",
  fontFamily: "Poppins",
  fontSize: "20px",
  padding: "10px 20px",
};

const Productcard = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  overflow: "hidden",
};

const Category = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const fetchAllCategories = async () => {
    const result = await axios.get(`${server}/category`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setCategories(result.data);
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else fetchAllCategories();
  }, []);

  //   useEffect(() => {}, [categories]);

  return (
    <Box sx={{ marginBottom: "100px", padding: 2 }}>
      <Typography variant="h6" sx={CategoryTitle}>
        Category
      </Typography>
      <Box>
        <Grid
          container
          spacing={3}
          // sx={{ padding: "10px 20px" }}
        >
          {(categories ?? []).map((currcategory, i) => {
            return currcategory.imageUrl != "noimage" ? (
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                }}
                key={currcategory._id}
              >
                <Link
                  to={`/subcategory/${currcategory._id}`}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <CategoryCard
                    name={currcategory.name}
                    imgsrc={currcategory.imageUrl}
                  />
                </Link>
              </Grid>
            ) : null;
          })}{" "}
        </Grid>
      </Box>
    </Box>

    //deleted static grids
  );
};

export default Category;
