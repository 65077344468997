// import { Box, Button, Typography } from "@mui/material";
// import { useState, useEffect } from "react";
// import axios from "axios";
// import server from "../../../server";
// import { Link } from "react-router-dom";
// import BannerShimmer from "../../Shimmers/BannerShimmer";

// const BannerDiv = {
//   width:"50%",
//   // display: "flex",
//   // justifyContent: "center",
//   // alignItems: "center",
//   padding: "40px 30px",
//   zIndex: 10,
// };

// const ButtonBanner = {
//   backgroundColor: "#3BB77E",
//   borderRadius: "3px",
//   color: "#fff",
//   padding: "3px 25px",
//   textTransform: "lowercase",
//   fontSize: "16px",
// };

// const TitleText = {
//   fontSize: "28px",
//   fontWeight: "700",
//   color: "#253D4E",
//   margin: "10px 0px",
//   fontFamily: "Quicksand",

// };
// const BannerDesc = {
//   fontSize: "20px",
//   fontWeight: "500",
//   color: "#838383",
//   margin: "5px 0px",
//   fontFamily: "Quicksand",
//   maxWidth: " 150px"
// };

// const Banner = (props) => {

//   //states
//   const [banner, setBanner] = useState()
//   const [id, setid] = useState("")
//   // let id;
//   const getBannerDetails = async () => {
//     // console.log(props.id)
//     if (props.id !== undefined) {
//       try {
//         const result = await axios.get(`${server}/banner/${props.id}`)
//         // console.log(result.data[0])
//         setBanner(result.data[0])
//         if (result.data[0].productId !== null) setid(result.data[0].productId)
//         else setid(result.data[0].categoryId)

//       } catch (error) {
//         console.log(error)
//         setBanner("yoo")
//       }

//     }
//   }

//   useEffect(() => {
//     getBannerDetails()
//   }, [props.id])

//   useEffect(() => {
//   }, [id, banner])

//   return (
//     <>
//       {
//         !banner ? <BannerShimmer /> : <Box className="banner-main-div" >
//           <Box>
//             {

//               banner.productId !== null ? <Link to={`/productdetail/${banner.productId}`} style={{ textDecoration: "none" }}>
//                 <Box sx={BannerDiv}>
//                   <Button sx={ButtonBanner}>{banner.discount} off</Button>
//                   <Typography variant="h4" sx={TitleText}>
//                     {banner.title}
//                   </Typography>
//                   <Typography sx={BannerDesc}>
//                     {banner.message}
//                   </Typography>

//                 </Box>
//               </Link>
//               ://link to a cateogry page
//               <Link to={`/subcategory/${banner.categoryId}`} style={{ textDecoration: "none" }}>
//               <Box sx={BannerDiv}>
//                 <Button sx={ButtonBanner}>{banner.discount} off</Button>
//                 <Typography variant="h4" sx={TitleText}>
//                   {banner.title}
//                 </Typography>
//                 <Typography sx={BannerDesc}>
//                   {banner.message}
//                 </Typography>

//               </Box>
//                 </Link>

//               }
//           </Box>
//           <Box>
//             <img src={banner.image} alt="fruit-img" className="fruit-img"
//               style={{
//                 maxWidth: "40vw",
//                 height:"100%"
//               }} />
//           </Box>

//         </Box>

//       }
//     </>
//   );
// };

// export default Banner;

import { useEffect, useState } from "react";
// import Carousel from "react-material-ui-carousel";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { StyledEngineProvider } from "@mui/material";
import { Carousel } from "flowbite-react";
import Item from "./Item";

function Banner({ min, max, items, index, toLoad, setToLoad }) {
  let arr = items?.slice(min, max);
  // console.log(arr)
  const [sliceditems, setItems] = useState();

  useEffect(() => {
    // console.log(sliceditems)
  }, [sliceditems]);
  //   useEffect(() => {
  //     setItems(arr);
  //   }, [items]);

  // const getbanners = async () => {
  //   const result = await axios.get(`${server}/banners`)
  //   setItems(result.data.filter(( item) => {
  //     return item.active
  //   }))
  // }
  // var items = [
  //   {
  //     name: "Free delivery over $50 ",
  //     description: "Shop $50 product and get free delivery anywhre.",
  //   },
  //   {
  //     name: "Best in the area",
  //     description: "Shop $50 product and get free delivery anywhre.",
  //   },
  // ];

  // useEffect(() => {
  //   getbanners()
  // }, [])
  const startLoad = () => {
    setItems(arr);
    !toLoad.includes(index + 1) &&
      setTimeout(() => setToLoad([...toLoad, index + 1]), 4000);
  };

  useEffect(() => {
    toLoad.includes(index) && !Boolean(sliceditems?.length) && startLoad();
  }, [toLoad, items]);

  const flowBiteTheme = {
    root: {
      base: "relative h-full w-full",
      leftControl:
        "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
      rightControl:
        "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none",
    },
    indicators: {
      active: {
        off: "bg-white/50 hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800",
        on: "bg-white dark:bg-gray-800",
      },
      base: "h-3 w-3 rounded-full",
      wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3",
    },
    item: {
      base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
      wrapper: {
        off: "w-full flex-shrink-0 transform cursor-default snap-center",
        on: "w-full flex-shrink-0 transform cursor-grab snap-center",
      },
    },
    control: {
      base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10",
      icon: "h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6",
    },
    scrollContainer: {
      base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
      snap: "snap-x",
    },
  };
  return (
    <StyledEngineProvider injectFirst>
      <Carousel
        // sx={{
        //   height: "fit-content",
        //   zIndex: 1,
        //   marginTop: "-20px",
        //   position: "relative",
        //   margin: "10px 0px 0px 0px",
        // }}
        // slideInterval={5000}
        // className="grid h-56 grid-cols-2 gap-4 sm:h-64 xl:h-80 2xl:h-96"
        //   infiniteLoop={true}
        //   autoPlay={true}
        //   showArrows={true}
        //   interval={5}
        //   onChange={onChange}
        //   onClickItem={onClickItem}
        //   onClickThumb={onClickThumb}
        // indicatorContainerProps={{
        //   style: {
        //   },
        // }}
        // sx={{ margin: "10px 0px 0px 0px" }}
        // className="h-56 sm:h-64 xl:h-80 2xl:h-96"
        // onSlideChange={(index) => console.log("onSlideChange()", index)}
        theme={flowBiteTheme}
        style={{ margin: "16px 0" }}
      >
        {sliceditems &&
          sliceditems.map((item, i) => <Item key={i} item={item} />)}
      </Carousel>
    </StyledEngineProvider>
  );
}

export default Banner;
