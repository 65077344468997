import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteIcon from "../../../../assets/Delete.png";
import UploadCloud from "../../../../assets/upload-cloud.svg";
import UploadImage from "../../../../assets/upload-image.svg";
import handleImageUpload from "../Utils/HandleImageUpload";

const ImageUpload = ({
  newProduct,
  setNewProduct,
  setImageFile,
  imagesSave,
  isEdit,
}) => {
  const [mainImage, setMainImage] = useState(imagesSave);
  // console.log(imagesSave);

  const handleDeleteImage = (index) => {
    setMainImage();
    setImageFile("noimage");
  };
  useEffect(() => {}, [mainImage]);
  const [image1, setImage1] = useState([]);

  const handleImageupload = async (event, index, main) => {
    if (main) {
      const file = event.target.files[0];
      // setImage1([file]);

      const file_url = await handleImageUpload({
        images: [file],
        setImages: setImage1,
        onError: () => toast.error("Image upload failed"),
      });
      // console.log(file_url);
      // const newImages = [...images];
      // newImages[0]=file_url
      setImageFile(file_url);
      // console.log(newImages);
      // setImages(newImages);

      setMainImage(file_url);
    }
  };

  const handleInput = (key, value) => {
    setNewProduct({
      ...newProduct,
      [key]: value,
    });
  };

  return (
    <div className="imageUpload-div">
      <div>
        <div className="upload-main-div">
          <input
            type="file"
            id={`image-upload`}
            accept="image/*"
            style={{ display: "none" }}
            onChange={(event) => handleImageupload(event, 0, true)}
          />
          {mainImage && (
            <div className="img-container">
              <div className="deleteImage">
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteImage(0)}
                >
                  <img src={DeleteIcon} alt="" />
                </button>
              </div>
              <img
                src={
                  typeof mainImage !== "string"
                    ? URL.createObjectURL(mainImage)
                    : mainImage
                }
                alt={`Preview `}
              />
            </div>
          )}
          {!mainImage && (
            <label htmlFor="image-upload">
              <div>
                <img
                  alt="upload-img"
                  src={UploadImage}
                  className="upload-image"
                />
                <div className="upload-cloud-div">
                  <img
                    alt="upload-img"
                    src={UploadCloud}
                    className="upload-cloud"
                  />
                  <p className="upload-text">Upload Image</p>
                </div>
                <p className="upload-desc">
                  Upload a cover image for your product.
                </p>
                <p className="upload-desc">
                  File Format <span>jpeg, png</span> Recommened Size
                  <span>600x600 (1:1)</span>
                </p>
              </div>
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
