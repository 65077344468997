import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import server from "../../server";
import WishlistProduct from "./Layout/WishlistProduct";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  margin: "0px 20px 0px 0px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  color: "#37474F",
};

const Wishlist = () => {
  const [wishlistDetails, setWishlistDetails] = useState([]);
  const [remove, setRemove] = useState("");
  const navigate = useNavigate();

  const getWishes = async () => {
    const result = await axios.get(
      `${server}/user/wishlist/status/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    if (result.data.length !== 0)
      setWishlistDetails(result.data[0].productList);
    // console.log(result.data)
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
  }, []);

  useEffect(() => {
    getWishes();
  }, [remove]);

  return (
    <Box
      sx={{
        // width:"100vw",
        marginBottom: " 100px",
      }}
    >
      <Box sx={header}>
        <Box sx={arrowStyle}>
          <Link to="/profile">
            <ArrowBackRoundedIcon />
          </Link>
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Wishlist
        </Typography>
      </Box>
      {wishlistDetails &&
        wishlistDetails.map((currWish) => {
          return (
            <WishlistProduct
              key={currWish}
              prodId={currWish}
              setRemove={setRemove}
            />
          );
        })}
    </Box>
  );
};

export default Wishlist;
