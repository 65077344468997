import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import { Box, IconButton, Typography } from "@mui/material";

const CardMainDiv = {
  padding: "20px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #E1E1E1",
  marginTop: "10px",
  "&.MuiBox-root:hover": {
    background: "rgba(94, 196, 1, 0.17)",
    borderRadius: "12px",
  },
};

const locationTitle = {
  fontSize: "18px",
  fontFamily: "quicksand",
  fontWeight: "600",
};

const addressText = {
  fontSize: "16px",
  fontFamily: "quicksand",
  fontWeight: "500",
  width: "90%",
};

const EditButton = { backgroundColor: "#F37A20", margin: "5px 5px" };

const PhoneButton = { backgroundColor: "#36B37E", margin: "5px" };

const Time = {
  color: "#37474F",
  fontSize: "12px",
  fontFamily: "quicksand",
  fontWeight: "500",
};

const NotificationCard = ({ subject, phone, star, notificationinfo }) => {
  return (
    <Box sx={CardMainDiv}>
      <Box>
        <Typography sx={locationTitle}>
          {notificationinfo.type === "order" ? (
            <> Order #{notificationinfo.orderId?.orderId} </>
          ) : (
            <> Return #{notificationinfo.returnId?.returnId} </>
          )}{" "}
        </Typography>
        <Typography sx={addressText}>{notificationinfo.message}</Typography>
      </Box>
      <Box>
        <Typography sx={Time}>
          {notificationinfo.createdAt.toString().substring(0, 10)}
        </Typography>
        {subject ? (
          <IconButton sx={EditButton}>
            <SubjectOutlinedIcon sx={{ color: "#fff" }} />
          </IconButton>
        ) : (
          ""
        )}

        {phone ? (
          <IconButton sx={PhoneButton}>
            <LocalPhoneOutlinedIcon sx={{ color: "#fff" }} />
          </IconButton>
        ) : (
          ""
        )}

        {star ? (
          <IconButton sx={PhoneButton}>
            <StarRoundedIcon sx={{ color: "#fff" }} />
          </IconButton>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default NotificationCard;
