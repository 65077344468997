import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import server from "../../server";
import CartProductCard from "./Layout/CartProductCard";

import ShoppingCart from "../ShoppingCart/ShoppingCart";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
};

const ButtonCart = {
  backgroundColor: "#5EC401",
  color: "#fff",
  textTransform: "none",
  padding: "10px",
  fontSize: "16px",
  fontWeight: "500",
  width: "90%",
  fontFamily: "Poppins",
  "&.MuiButtonBase-root:hover": {
    background: "#64cf00",
  },
  mx: "5%",
};

const Cart = () => {
  const navigate = useNavigate();

  //states
  const [cart, setCart] = useState([]);
  const [cartprice, setCartPrice] = useState(localStorage.getItem("cartprice"));

  const getcartinfo = async () => {
    setCart(JSON.parse(localStorage.getItem("cart")));
  };

  const handleFlow = async () => {
    const addressresult = await axios.get(
      `${server}/address/user/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );

    // console.log(addressresult.data)

    //check for validity of order minimum rs 500
    if (cartprice < 1000) {
      toast.warning("Cannot place orders below ₹1000", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (addressresult.data.length === 0) {
      //go to add address page with status true
      navigate("/addaddress", { state: { status: "true" } });
    } else {
      navigate("/myaddress", { state: { status: "true" } });
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getcartinfo();
  }, []);

  return (
    <>
      <ToastContainer />
      {JSON.parse(localStorage.getItem("cartprice")) === 0 ? (
        <ShoppingCart />
      ) : (
        <Box sx={{ marginBottom: "100px" }}>
          <Box sx={header}>
            <Box sx={arrowStyle}>
              <Link to="/">
                <IconButton>
                  <ArrowBackRoundedIcon />
                </IconButton>
              </Link>
            </Box>
            <Typography variant="h6" sx={CategoryTitle}>
              Cart
            </Typography>
          </Box>

          {cart &&
            cart.map((prod, i) => {
              return (prod.length === 3 && parseInt(prod[2]) > 0) ||
                (prod.length == 2 && parseInt(prod[1]) > 0) ? (
                <CartProductCard
                  proddetail={prod}
                  setCartPrice={setCartPrice}
                  key={i}
                />
              ) : (
                <></>
              );
            })}
          <Box
            sx={{
              // padding: "0px 10px",
              position: "fixed",
              bottom: 0,
              // left: "50vw",
              // backgroundColor: "#fff",
              // borderRadius: "7px",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // zIndex: 10,
              width: "100%",
              // padding: "10px",
              marginBottom: "100px",
            }}
          >
            {/* <Link to="/placeorder"> */}
            <Button
              sx={ButtonCart}
              onClick={(e) => {
                handleFlow();
              }}
            >
              Checkout
            </Button>
            {/* </Link> */}
          </Box>
          {cartprice && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "10px 20px",
                marginBottom: "150px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700px",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                }}
              >
                Total{" "}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "700px",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                }}
              >
                ₹{cartprice}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Cart;
