import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfilePic from "../../../assets/image 1.svg";
import MailIcon from "../../../assets/Mail.svg";
import ProfileIcon from "../../../assets/Profile.svg";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#ffffff",
  border: "none",
  borderRadius: "12px",
  boxShadow: 24,
  p: 2,
  backdropfilter: "blur(4px)",
};

const TextContainer = {
  backgroundColor: "#EFF1F9",
  width: "100%",
  minWidth: "fit-content",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderRadius: "10px",
  padding: "15px",
  marginBottom: "10px",
  // maxWidth:""
};

const header = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  // alignItems: "center",
  padding: " 20px 20px 20px 20px",
};

const label = {
  fontWeight: 400,
  fontSize: "12px",
  color: "#5E6366",
  paddingBottom: "10px",
};

const textcontent = {
  fontWeight: 400,
  fontSize: "16px",
  color: "#5E6366",
  paddingLeft: "10px",
};

const Settings = () => {
  //states
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        // height: "100vh",
        minWidth: "700px",
        margin: "10px 1px 10px 1px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={header}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "20px",
          }}
        >
          Account Settings
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "40px",
          // alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "30vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={label}> First Name </Typography>
            <Box sx={TextContainer}>
              <Box>
                <img src={ProfileIcon} alt="" />
              </Box>
              <Typography sx={textcontent}>Usman</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={label}> Last Name </Typography>
            <Box sx={TextContainer}>
              <Box>
                <img src={ProfileIcon} alt="" />
              </Box>
              <Typography sx={textcontent}>Ndeko</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={label}> Email </Typography>
            <Box sx={TextContainer}>
              <img src={MailIcon} alt="" />

              <Typography sx={textcontent}>usman@gmail.com</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={label}> Phone Number </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#EFF1F9",
                  width: "10vw",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "10px",
                  // paddingLeft: "10px",
                  // paddingRight: "30px",
                  marginBottom: "10px",
                  marginRight: "10px",
                  padding: "15px",
                  // maxWidth:""
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#5E6366",
                  }}
                >
                  +91
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#EFF1F9",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                  marginRight: "10px",
                  width: "100%",
                  // maxWidth:""
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#5E6366",
                    width: "100%",
                  }}
                >
                  08065650633
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* //image of admin */}
        <Box
          sx={{
            marginLeft: "20px",
            borderRadius: "10px",
          }}
        >
          <img
            src={ProfilePic}
            alt=""
            style={{
              width: "100%",
              height: "50%",
              borderRadius: "10px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
