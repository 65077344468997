import axios from "axios";
import { useContext, useEffect, useState } from "react";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
import AllOrders from "../Dashboard/Layout/AllOrders";
import OrderTable from "./Layout/OrderTable";
// import * as React from 'react';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminOrders = () => {
  const [getOrder, setGetOrder] = useState([]);
  const navigate = useNavigate();

  const [customerByOrder, setcustomerByOrder] = useState([]);
  const [origetOrder, setOriGetOrder] = useState([]);
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };

  const dataOrders = async () => {
    // let orders=[];
    setGetOrder([]);
    setLoading(true);
    const res = await axios.get(`${server}/orders`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setGetOrder(res.data);
    setOriGetOrder(res.data);
    setLoading(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
    else dataOrders();
  }, []);

  useEffect(() => {}, [value, getOrder]);

  return (
    <div>
      <div className="order-head">
        <p className="order-title">Orders Summary</p>
      </div>
      {/* <div className="order-grid"> */}
      <div className="allorders">
        <AllOrders orders={getOrder} type={"Orders"} />
      </div>
      {/* </div> */}
      {getOrder.length > 0 ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Pending" {...a11yProps(0)} />
              <Tab label="In Progress" {...a11yProps(1)} />
              <Tab label="Cancelled " {...a11yProps(2)} />
              <Tab label="Delivered" {...a11yProps(3)} />
              <Tab label="All Orders" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <OrderTable
              getOrder={getOrder}
              orderstatus="pending"
              functionchange={dataOrders}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <OrderTable
              getOrder={getOrder}
              orderstatus="inprogress"
              functionchange={dataOrders}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <OrderTable
              getOrder={getOrder}
              orderstatus="cancelled"
              functionchange={dataOrders}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <OrderTable
              getOrder={getOrder}
              orderstatus="delivered"
              functionchange={dataOrders}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <OrderTable
              getOrder={getOrder}
              orderstatus="all"
              functionchange={dataOrders}
            />
          </CustomTabPanel>
        </Box>
      ) : (
        <></>
      )}
      {/* <div>
        <OrderTable getOrder={getOrder} />
      </div> */}
    </div>
  );
};

export default AdminOrders;
