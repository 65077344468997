import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Box, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import server from "../../server";
import OrderCard from "./Layout/OrderCard";
import TrackOrderCard from "./Layout/TrackOrderCard";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "15px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "500",
  fontFamily: "Poppins",
};

const checkMainDiv = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px",
};

const checkDiv = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  margin: "0px 20px",
  position: "relative",
};

const CheckStyle = {
  backgroundColor: "#6CC51D",
  color: "#fff",
  borderRadius: "50%",
  padding: "8px",
  width: "32px",
  height: "32px",
};

const CheckNo = {
  backgroundColor: "#6CC51D",
  color: "#fff",
  borderRadius: "50%",
  //   padding: "8px",
  width: "32px",
  height: "32px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const checkText = {
  textTransform: "uppercase",
  fontWeight: "500",
  fontFamily: "Poppins",
  fontSize: "13px",
  color: "#868889",
};

const CheckLine = {
  height: "1.5px",
  backgroundColor: "#6CC51D",
  width: "90px",
  position: "absolute",
  right: "-80px",
  top: "28%",
};

const CheckLineAdd = {
  height: "1.5px",
  backgroundColor: "#6CC51D",
  width: "90px",
  position: "absolute",
  right: "-70px",
  top: "28%",
};

const TrackOrder = () => {
  const { orderId } = useParams();

  const [orderinfo, setOrderInfo] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const getOrderDetails = async () => {
    const result = await axios.get(`${server}/orders/id/${orderId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)
    setOrderInfo(result.data);
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
  }, [navigate]);

  useEffect(() => {
    getOrderDetails();
  }, [orderId]);

  useEffect(() => {
    console.log("orderinfo", orderinfo);
  }, [orderinfo]);

  return (
    <Box sx={{ background: "#F4F5F9", marginBottom: "100px" }}>
      <Box sx={header}>
        <Box sx={arrowStyle}>
          {orderinfo ? (
            <IconButton
              onClick={(e) => {
                // e.preventDefault()
                navigate(`/vieworder/${orderId}`, {
                  state: { value: location?.state?.value },
                });
              }}
            >
              <ArrowBackRoundedIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={(e) => {
                // e.preventDefault()
                navigate(`/returnorder/view/${orderId}`, {
                  state: { value: location?.state?.value },
                });
              }}
            >
              <ArrowBackRoundedIcon />
            </IconButton>
          )}
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Track Order
        </Typography>
      </Box>
      <Box sx={checkMainDiv}>
        <Box sx={checkDiv}>
          <CheckRoundedIcon sx={CheckStyle} />
          <Typography sx={checkText}>Login</Typography>
          <Box>
            <Box sx={CheckLine}></Box>
          </Box>
        </Box>
        <Box sx={checkDiv}>
          <CheckRoundedIcon sx={CheckStyle} />
          <Typography sx={checkText}>Address</Typography>
          <Box>
            <Box sx={CheckLineAdd}></Box>
          </Box>
        </Box>
        <Box sx={checkDiv}>
          <Typography sx={CheckNo}>3</Typography>
          <Typography sx={checkText}>
            {orderinfo ? "Delivery" : "Return"}
          </Typography>{" "}
        </Box>
      </Box>
      <Box sx={{ padding: "10px 20px" }}>
        {/* <Link to="/cartempty" style={{ textDecoration: "none" }}> */}
        <OrderCard orderId={orderId} />
        {/* </Link> */}
        <TrackOrderCard orderId={orderId} />
      </Box>
    </Box>
  );
};

export default TrackOrder;
