import User from "../../../../assets/User.svg";

const AllCustomers = (props) => {
  return (
    <div className="abandoned-main">
      <div className="abandoned-div">
        <img alt="cart-img" src={User} className="cart-image" />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className="abandoned-body">
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">All Customers</p>
          <p className="cart-percentage">
            {props.customers.length}
            <span>+15.80%</span>
          </p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">Active</p>
          <p className="cart-percentage">
            {props.active_users}
            <span>+85%</span>
          </p>
        </div>
        <div className="abandoned-body-item">
          <p className="abandoned-body-title">In-Active</p>
          <p className="cart-percentage cart-per">
            {props.no_users - props.active_users}{" "}
            <span className="loss">-10%</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AllCustomers;
