import { Box, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import noimage from "../../../../assets/noimage.jpg";
import server from "../../../server";
import CategoryCard from "./CategoryCard";

const ViewCategoryGrid = {
  padding: "1vh 10px",
};

const ViewCategoryGridItem = {
  display: "flex",
  justifyContent: "center",
};

const ViewCategoryText = {
  padding: "10px",
  backgroundColor: "#fff",
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "16px",
  // lineHeight: 1,
  //   boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
  borderRadius: "3px",
};

const SubCategorysection = ({ categoryId }) => {
  const [subcategoryList, setSubCategoryList] = useState([]);
  const [loadCount, setLoadCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [catName, setCatName] = useState("");

  useEffect(() => {
    setLoading(true);
    const getSubcategories = async () => {
      if (categoryId) {
        const subcategoryData = await axios.get(
          `${server}/subcategory/find/${categoryId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );
        setSubCategoryList(subcategoryData?.data);
      }
    };

    const getCategory = async () => {
      if (categoryId) {
        const categoryData = await axios.get(
          `${server}/category/${categoryId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );
        setCatName(categoryData.data.name);
      }
    };
    getSubcategories();
    getCategory();
    setLoading(false);
  }, []);

  return (
    <>
      {!loading ? (
        <Box
          sx={{
            backgroundColor: "#F2F2F2",
            flexGrow: 1,
            borderRadius: "3px",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ ...ViewCategoryText, lineHeight: "1.5" }}
            //   key={"subtitle1"}
          >
            {categoryId ? catName : "View by category"}
          </Typography>
          <Grid container spacing={2} sx={ViewCategoryGrid}>
            {
              // i had kept this as i>5 to restrict only 6 categories removed it temporarily
              // categories &&
              subcategoryList?.map((currcategory, i) => {
                const imageUrlToUse =
                  currcategory.imageUrl && currcategory.imageUrl !== ""
                    ? currcategory.imageUrl
                    : noimage;
                return (
                  currcategory.imageUrl != "noimage" && (
                    <Grid item xs={4} key={i} style={ViewCategoryGridItem}>
                      <CategoryCard
                        categoryname={currcategory.name}
                        categoryid={currcategory._id}
                        categoryimage={imageUrlToUse}
                        propid={categoryId}
                        key={i}
                        setLoadCount={setLoadCount}
                      />
                    </Grid>
                  )
                );
              })
            }
          </Grid>
        </Box>
      ) : (
        <Grid container spacing={2} sx={ViewCategoryGrid}>
          {[1, 2, 3].map((i) => (
            <Grid item xs={4} key={i} style={ViewCategoryGridItem}>
              <Skeleton
                style={{
                  width: "40vw",
                  height: "40vw",
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default SubCategorysection;
