import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import server from "../../../server";
import DashboardContext from "../../Context/DashboardContext";
import handleImageUpload from "../../Inventory/Utils/HandleImageUpload";

function Farm_equip() {
  const theme = useTheme();

  const [equipments, setEquipments] = useState([]);
  const [crops, setCrops] = useState([]);
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      headers: {
        //   method: "GET",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    axios
      .get(`${server}/equipment/getequipment`, requestOptions)
      .then((res) => {
        console.log(res.data.results);
        setEquipments(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${server}/crops/getcrops/`, requestOptions)
      .then((res) => {
        console.log(res.data.results);
        let crop_id = [];
        res.data.results?.map((item) => {
          crop_id.push({ id: item.id, name: item.name });
        });
        setCrops(crop_id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [id, setId] = useState(-1);
  const [equi_data, setEqui_data] = useState({
    name: "",
    crops: [],
    image: "",
    icon: "",
  });

  const handleInput = (key, value) => {
    setEqui_data({ ...equi_data, [key]: value });
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [images, setImages] = useState([]);
  const [icons, setIcons] = useState([]);
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const handleupload = () => {
    const imginput = document.getElementById("equi_img");
    imginput.click();
    const geturl = (e) => {
      const file = e.target.files[0];
      //  console.log(file)
      if (!file) return;
      setImages([file]);
    };
    imginput.addEventListener("change", geturl);
  };
  const [crop_data, setCrop_data] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(event.target);
    setCrop_data(typeof value === "string" ? value.split(",") : value);
  };

  const refresh = () => {
    for (const key in equi_data) equi_data[key] = "";
    setEqui_data({ ...equi_data, crops: [] });
    setCrop_data([]);
  };
  useEffect(() => {
    handleInput("crops", crop_data);
    console.log(crop_data);
  }, [crop_data]);
  useEffect(() => {
    handleImageUpload({ images: images, setImages: setImages })
      .then((res) => {
        const img_url = res;
        // console.log(img_url);
        setEqui_data({ ...equi_data, image: img_url });
        document.querySelector(".equi_img").src = img_url;
      })
      .catch((err) => console.log(err));
  }, [images]);
  const handleupload1 = () => {
    const imginput = document.getElementById("equi_icon");
    imginput.click();
    const geturl = (e) => {
      const file = e.target.files[0];
      //  console.log(file)
      if (!file) return;
      setIcons([file]);
    };
    imginput.addEventListener("change", geturl);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    handleImageUpload({ images: icons, setImages: setIcons })
      .then((res) => {
        const img_url = res;
        // console.log(img_url);
        setEqui_data({ ...equi_data, icon: img_url });
        document.querySelector(".equi_icon").src = img_url;
      })
      .catch((err) => console.log(err));
  }, [icons]);
  const [equi_data_update, setEqui_data_update] = useState([]);
  const handleEdit = (row) => {
    handleOpen();
    setEqui_data({ ...row });
    setEqui_data_update({ ...row });
    setCrop_data(row.crops);
    setId(row.id);
    // console.log(row.image);
  };
  // useEffect(()=>{
  //   if(equi_data?.image!="")
  //   document.querySelector(".equi_img").src=equi_data?.image;

  // },[equi_data?.image])

  const handlesubmit = () => {
    setLoading(true);
    // console.log(equi_data);
    // console.log(offer_data);
    console.log(id);
    const token = `Bearer ${localStorage.getItem("access")}`;
    if (id >= 0) {
      let equi_update = {};
      for (const key in equi_data) {
        if (equi_data_update[key] != equi_data[key])
          equi_update = { ...equi_update, [key]: equi_data[key] };
      }
      if (Object.keys(equi_update).length) {
        console.log(equi_update);
        axios
          .patch(`${server}/equipment/updateEquipment/${id}`, {
            equi_update,
            token,
          })
          .then((res) => {
            console.log(res.data);
            toast.success("Equiment Updated!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            handleClose();
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            handleClose();

            setLoading(false);
          });
      }
    } else {
      console.log(equi_data);
      axios
        .post(`${server}/equipment/createEquipment`, { equi_data, token })
        .then((res) => {
          console.log(res.data);
          toast.success("Equiment Created!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleClose();
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleClose();

          setLoading(false);
        });
    }
  };
  const GetCrop = ({ id }) => {
    // console.log(id);
    const crop = crops.filter((item) => {
      return item.id == id;
    });
    // console.log(crop);
    return <p style={{ padding: "5px" }}>{crop[0]?.name} </p>;
  };

  return (
    // <>
    // </>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <p>All Equipments</p>
        <button
          onClick={() => {
            handleOpen(-1);
            refresh();
          }}
          className="create-new-post-button"
        >
          Create New Equipment
        </button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-head krishi-modal-head">
            <p className="modal-title">Create New Equipment</p>
            <button className="close-button-krishi" onClick={handleClose}>
              X
            </button>
          </div>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <p className="Customer-info">Name</p>
              <input
                placeholder="Enter Title"
                className="product-input"
                style={{ width: "350px", margin: "5px 0px" }}
                value={equi_data?.name}
                onChange={(e) => handleInput("name", e.target.value)}
              />
              {/* <select className='product-select1' value={equi_data.crop_id} onChange={(e) => handleInput("crop_id", e.target.value)}>
                     {crops?.map((item)=>(
                        <option value={item.id}>{item.name}</option>
                     ))}
                  </select> */}
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">Crops</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={crop_data}
                  onChange={handleChange}
                  input={<OutlinedInput label="Name" />}
                  MenuProps={MenuProps}
                >
                  {crops?.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      style={getStyles(item.name, crop_data, theme)}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p
                style={{ margin: "0px 0px 10px 0px" }}
                className="Customer-info"
              >
                Upload Image
              </p>
              {equi_data?.image != "" ? (
                <img
                  style={{ width: "3rem", height: "3rem" }}
                  src={equi_data?.image}
                  className="equi_img"
                />
              ) : (
                <></>
              )}
              <button
                className="upload-image-button"
                onClick={handleupload}
                style={{ marginBottom: "1rem", width: "40%" }}
              >
                <input type="file" accept="image/*" hidden id="equi_img" />
                Upload image
              </button>
              <p
                style={{ margin: "0px 0px 10px 0px" }}
                className="Customer-info"
              >
                Upload Icon
              </p>
              {equi_data?.icon != "" ? (
                <img
                  style={{ width: "3rem", height: "3rem" }}
                  className="equi_icon"
                  src={equi_data?.icon}
                />
              ) : (
                <></>
              )}
              <button
                className="upload-image-button"
                onClick={handleupload1}
                style={{ marginBottom: "1rem", width: "40%" }}
              >
                <input type="file" accept="image/*" hidden id="equi_icon" />
                Upload icon
              </button>
              <button
                className="upload-image-button"
                style={{ marginTop: "1rem", width: "40%" }}
                onClick={handlesubmit}
              >
                Save
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Icon</TableCell>
              <TableCell>Crops</TableCell>
              {/* <TableCell>Status</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {equipments?.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <img
                    style={{ width: "5rem", height: "5rem" }}
                    src={row.image}
                  />
                </TableCell>
                <TableCell>
                  <img
                    style={{ width: "5rem", height: "5rem" }}
                    src={row.icon}
                  />
                </TableCell>

                <TableCell>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {row.crops.map((item) => {
                      return <GetCrop id={item} />;
                    })}
                  </div>
                </TableCell>
                {/* <TableCell>{row.status}</TableCell> */}
                <TableCell>
                  <button
                    className="edit-button"
                    onClick={() => handleEdit(row)}
                  >
                    Edit
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Farm_equip;
