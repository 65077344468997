import CloseIcon from "@mui/icons-material/Close";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Input, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import { alpha, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import server from "../../../server";
import DashboardContext from "../../Context/DashboardContext";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "Pincode",
    numeric: false,
    disablePadding: true,
    label: "Pincode",
  },
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Created Date/Time",
  },
  {
    id: "Edit",
    numeric: false,
    disablePadding: true,
    label: "Edit",
  },
];

//this is the header of table basically showing names of the columns and the sort buttons for each
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//this is for search functionality in table
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Pincodes
          </Typography>
        )}
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const PinCodeList = (props) => {
  // console.log(props.rows);
  //rows are basically your data
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [active_users, setActive_users] = useState(0)
  const [pincodeList, setPinCodeList] = useState([]);
  const [orirows, setorirows] = useState([]);

  //addpincode modal states
  const [open, setOpen] = useState(false);
  const [newpincode, setNewPincode] = useState();
  const [newpincodename, setNewPincodeName] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [edit, setedit] = useState(false);
  const [editPincode, setEditPincode] = useState("");
  const [newName, setNewName] = useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pincodeList?.length) : 0;

  const getPinCodeList = async () => {
    const result = await axios.get(`${server}/admin/pincodes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)
    setPinCodeList(result.data);
    setorirows(result.data);
  };

  const createPincode = async () => {
    if (newpincode) {
      try {
        const result = await axios.post(
          `${server}/admin/addpincode`,
          {
            pincode: newpincode,
            name: newpincodename,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );

        console.log({ result: result.data });
        setNewPincode();
        getPinCodeList();
        handleClose();
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("please enter a pincode");
    }
  };

  useEffect(() => {
    getPinCodeList();
  }, []);

  // useEffect(() => {}, [pincodeList, orirows, newpincode]);

  const submitEdit = () => {
    const result = axios.post(
      `${server}/admin/editpincode`,
      {
        pincode: editPincode,
        name: newName,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setEditPincode("");
    setNewName("");
    // await getPinCodeList();
    window.location.reload();
  };

  return (
    <div>
      <Box sx={{}}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Button
            sx={{
              // textTransform: "none",
              // backgroundColor: "#5570F1",
              // color: "#FFFFFF",
              // fontWeight: 500,
              // fontSize: "20px",
              // padding: "10px",
              // paddingLeft: "40px",
              // paddingRight: "40px",
              // borderRadius: "15px",
              marginBottom: "3vh",
              // "&:hover": {
              //   //you want this to be the same as the backgroundColor above
              //   backgroundColor: "#5570F1",
              // },
            }}
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              handleOpen();
            }}
          >
            Add Pincode
          </Button>
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50vw",
                // height:  "50vh",
                bgcolor: "background.paper",
                borderRadius: "12px",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDIrection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: 1,
                }}
              >
                <Typography
                  sx={{
                    marginBottom: "10px",
                    fontWeight: 500,
                  }}
                >
                  Add New Pincode
                </Typography>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Typography
                sx={{
                  marginBottom: "10px",
                }}
              >
                Enter Pincode
              </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "#EFF1F9",
                }}
                onChange={(e) => setNewPincode(e.target.value)}
              />
              <Typography
                sx={{
                  marginBottom: "10px",
                }}
              >
                Enter Name
              </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "#EFF1F9",
                }}
                onChange={(e) => setNewPincodeName(e.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    display: "flex",
                    textTranform: "none",
                    backgroundColor: "#5570F1",
                    color: "#FFFFFF",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    createPincode();
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            rows={pincodeList}
            setRows={setPinCodeList}
            orirows={[]}
          />
          <TableContainer style={{ padding: "0px 20px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={pincodeList?.length}
              />
              <TableBody>
                {(rowsPerPage > 0
                  ? pincodeList?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : pincodeList
                )?.map((row, index) => {
                  // const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // if(row?.is_active=="true")
                  // props.setActive_users(props.active_users+1);

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      // selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      style={{
                        border: "1px solid transparent",
                      }}
                    >
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {row.pincode}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {!edit && editPincode === row.pincode ? (
                          <div
                            style={{
                              display: "flex",
                              gap: 20,
                            }}
                          >
                            <Input
                              placeholder={row.name}
                              onChange={(e) => setNewName(e.target.value)}
                              value={newName}
                            />{" "}
                            <Button
                              variant="contained"
                              style={{
                                textTransform: "none",
                              }}
                              onClick={submitEdit}
                            >
                              Submit
                            </Button>
                          </div>
                        ) : (
                          row.name
                        )}
                      </TableCell>

                      <TableCell
                        padding="none"
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {format(
                          new Date(row.createdAt),
                          "dd MMMM yyyy - hh:mm a",
                        )}
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        <button
                          className="edit-button"
                          onClick={(e) => {
                            setEditPincode(row.pincode);
                            setedit(!edit);
                            setNewName(row.name);
                          }}
                        >
                          Edit
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 25]}
            component="div"
            count={pincodeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default PinCodeList;
