import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";
import SearchResult from "./Layout/SearchResult";

const arrowStyle = {
  margin: "0px 10px",
};

const Search = () => {
  const { navQueryState } = useLocation().state ?? {};
  const [searchquery, setQuery] = useState(navQueryState ?? "");
  const [tempQuery, setTempQuery] = useState(navQueryState ?? "");
  const InputPropsStyle = {
    sx: {
      fieldset: {
        borderRadius: 0,
        borderWidth: 0,
      },
      "&:focus-within fieldset, &:focus-visible fieldset": {
        border: "none!important",
      },
      input: {
        "&::placeholder": {
          color: "#5EC401 !important",
          fontSize: "14px",
          padding: "0px 0px 0px 10px",
          mixBlendMode: "normal",
          opacity: "0.87",
        },
      },
    },
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => setQuery(tempQuery)}>
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    ),
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
  }, []);

  return (
    <Box sx={{ marginBottom: "100px", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Box sx={arrowStyle} onClick={() => history.go(-1)}>
          {/* <Link to="/"> */}
          {/* <Button > */}
          <ArrowBackRoundedIcon />
          {/* </Button> */}
          {/* </Link> */}
        </Box>

        <TextField
          size="small"
          placeholder="Search"
          //   onBlurCapture={ (e) =>{
          //     console.log("clicked ")
          //   }}
          value={tempQuery}
          onChange={(e) => {
            setTempQuery(e.target.value);
          }}
          onKeyDown={(e) => e.key === "Enter" && setQuery(tempQuery)}
          sx={{
            backgroundColor: "#F0F1F2",
            borderRadius: "8px 8px 0px 0px",
            width: "100%",
            margin: "10px 20px 10px 0px",
            borderBottom: "1px solid #5EC401",
          }}
          InputProps={InputPropsStyle}
          InputLabelProps={{
            sx: {
              color: "#5EC401",
              textTransform: "capitalize",
            },
          }}
          // value={searchquery}
        />
      </Box>

      {/* <Recommendations /> */}

      {/* <Discover />  */}
      <SearchResult query={searchquery} />
      {/* <ProductNotFound /> */}
    </Box>
  );
};

export default Search;
