import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import server from "../../server";
import NotificationCard from "./Layout/NotificationCard";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  margin: "0px 20px 0px 0px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  color: "#37474F",
};

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const getnotifications = async () => {
    const result = await axios.get(
      `${server}/notifications/user/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setNotifications(result.data.reverse());
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getnotifications();
  }, []);

  useEffect(() => {
    // console.log(notifications)
  }, [notifications]);

  return (
    <Box sx={{ marginBottom: "100px" }}>
      <Box sx={header}>
        <Box sx={arrowStyle}>
          <Link to="/profile">
            <ArrowBackRoundedIcon />
          </Link>
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Notifications
        </Typography>
      </Box>

      <Box sx={{ padding: "0px 10px" }}>
        {notifications &&
          notifications.map((element) => {
            return (
              <NotificationCard subject="phone" notificationinfo={element} />
            );
          })}
      </Box>
    </Box>
  );
};

export default Notification;
