import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAaIuhAzfZm7P-Qjm3hmOduF90uDA1jKjY",
  authDomain: "estore-v1.firebaseapp.com",
  projectId: "estore-v1",
  storageBucket: "estore-v1.appspot.com",
  messagingSenderId: "154568484477",
  appId: "1:154568484477:web:0aeda93510d95e07683314",
  measurementId: "G-2S99LBKJSC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
