//this private route is to redirect the user if they are logged in and try to come back to login page
// if they are logged in as admin nav to dashboard or if client nav to /home

import { Navigate } from "react-router-dom";

const PrivateLogin = ({ children }) => {
  return localStorage.getItem("adminuser") ? (
    <Navigate to="/dashboard" />
  ) : localStorage.getItem("user") ? (
    <Navigate to="/home" />
  ) : (
    children
  );
};

export default PrivateLogin;
