import { Box } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
// import server from '../../server'
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import server from "../../server";
import KrishiVideos from "./KrishiVideos";
import Crop_table from "./Layout/Crop_table";
import Farm_equip from "./Layout/Farm_equip";
import { OfferTabs } from "./Layout/Offers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Content = () => {
  const [value, setValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [orirows, setOriRows] = useState([]);

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };
  const getCrop = () => {
    axios
      .get(`${server}/crops/getCrops`, requestOptions)
      .then((res) => {
        setRows(res.data.results);
        setOriRows(res.data.results);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getCrop();
  }, []);

  // useEffect(()=>{console.log(rows)
  // },[rows])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box>
        <Box sx={{ borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* <Tab
              sx={{ fontSize: 14, color: "#45464E", fontWeight: 500 }}
              label="Home Page "
              {...a11yProps(0)}
            /> */}
            <Tab label="Crops" {...a11yProps(0)} />
            <Tab label="Offers" {...a11yProps(1)} />
            {/* <Tab label="Store" {...a11yProps(3)} /> */}
            <Tab label="Krishi Videos" {...a11yProps(2)} />
            <Tab label="Farm Equipments" {...a11yProps(3)} />
          </Tabs>
        </Box>
        {/* <TabPanel value={value} index={0}>
          Comming Soon......
        </TabPanel> */}
        <TabPanel value={value} index={0}>
          <Crop_table rows={rows} setRows={setRows} orirows={orirows} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OfferTabs />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <KrishiVideos />

          {/* <EnhancedTableToolbar /> */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Farm_equip />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Content;
